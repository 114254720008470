








import Layout from '@/components/Layout.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'archived-loans',
  components: { Layout },
  props: {},

  setup() {
    return {}
  },
})
